<template>
    <h3 class="pb-4">Select car variant</h3>
    <!-- <input type="text" maxlength="30" autocomplete="off" 
            placeholder="select car variant"
            class="form-control form-control-lg" 
            
            > -->
    
    <div class="mt-5 class=">
        <div class="row row-overflow-scroll">
            <h6>Popular variants</h6>
            <div class="col-6" v-for="variant in this.variants" :key="variant.variant_id">
                <div 
                    v-bind:class="variant.variant_id === selected_variant?.variant_id ? 'border p-2 mb-3 rounded text-center bg-warning': 'border p-2 mb-3 rounded text-center'">
                    <h5 @click="updateVariant" v-bind:id="variant.variant_id">{{ variant.variant_display_name }} </h5>    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// import HelloWorld from './components/HelloWorld.vue'
import {ref} from 'vue'
//import { c24Service } from "@/service";

export default {
  name: 'VariantSelection',
  props: {
    //header: String,
    //title: String,
    disbaled: Boolean,
    brand: String
  },
  emits:[
    "run-validation",
  ],
  setup() {
    let searchTerm = ref('')
    return {
      searchTerm
    }
  },
  data() {
    return {
        loading: false,
        variants: null,
        selected_variant: null, //this.$store.state.variant,
        fuel_type: this.$store.state.fuel_type,
        transmission_type: this.$store.state.transmission_type,
        error: null,
    }
  },
  created() {
        this.fetchData()
  },
  methods: {
    fetchData(){
        this.error = null
        this.variants = this.$store.state.api_variants[this.fuel_type].filter((variant) => variant.title == this.transmission_type)[0].variants || []
        this.variants =  this.variants.filter((v) => this.isVariantEligible(v.variant_id)) 
        console.log("this.variants", this.variants);

        // console.log(this.$store.state.model.model_id, this.$store.state.year);
        // c24Service.getVariants( this.$store.state.model.model_id, this.$store.state.year).then(response => {
        //     console.log("response.data.detail[this.fuel_type]", response.data.detail[this.fuel_type])
        //     this.variants = response.data.detail[this.fuel_type].filter((variant) => variant.title == this.transmission_type)[0].variants
        //     this.loading = false`
        //     //console.log(this.variants)
        // }).catch(e => {
        //     console.log(e)
        //     this.error = e
        //     this.loading = false
        // })
    },
    getVariants: function(variant_id){
        return this.variants.filter((variant)=> variant.variant_id.toString() === variant_id)[0]
    },
    updateVariant: function(event) {
        //console.log(event.target.id);
        this.selected_variant = this.getVariants(event.target.id)
        this.$store.commit('changeVariant', {
            variant: this.selected_variant
        })
        this.$emit('run-validation', {
            data:this.get_data(),
            is_valid: this.isValid()
        })
    },
    isVariantEligible(variant_id){
      //console.log("variant_id", variant_id)
      const m = this.$store.state.EligibleVariants.filter((variant) => variant.id.toString() === variant_id.toString()) || []
      //console.log("a", m, m?.length)
        if ( m.length ){
            return true
        }
        else { return false }
        
    },
    get_data(){
        return {
          variant: this.selected_variant
        }
    },
    isValid(){
        if (this.selected_variant && this.selected_variant.variant_id){
            return true
        }
        return false

    }
  }
    
}
</script>