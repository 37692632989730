<template>

  <section class="mt-5">
    <div class="container-fluid py-5">

      <div class="row d-flex align-items-center justify-content-center h-100 ">
        <div class="col-md-7 border rounded-4 b-5 col-lg-3 col-xl-3 p-5 bg-white">
          <div class="text-center">
            <img class="mb-3" width="80" height="39" style="width:80;height:39" src="@/assets/cars-logo.png" alt="logo">
            
            <h4 class="m-3 pl-5 pr-5">Login / Signup</h4>
            
          </div>
          <!-- Email input -->
          <div class="form-outline mb-4">
            <h6 class="text-danger text-center" v-if="wrongCred">Authentication Failed.</h6>
            <h6 class="text-danger text-center" v-if="errors">{{ errors }}</h6>
            <h6 class="text-danger text-center" v-if="this.isEmail() && !this.isC24Email()">Please enter cars24 email</h6>
          </div>
          <div class="form-outline mb-4" v-if="!is_otp_send">
            <!-- <label class="form-label" for="form1Example13" >email Number {{ this.email  }}</label> -->
              <input type="text" id="form1Example13"
                  maxlength="100" 
                  placeholder="email"
                  class="form-control form-control-lg form-control-mob-no text-center" 
                  v-model="email" />
          </div>
          
          <div class="form-outline mb4 text-center" v-if="is_otp_send">
            <p>OTP Sent on</p>
            <h4 class="text-center">{{ this.email }}</h4>
          </div>
          
          <div v-if="is_otp_send" class="form-outline mb-4 form-pwd">
              <input type="password" maxlength="6" id="form1Example23" placeholder="OTP" class="form-control form-control text-center"  v-model="otp" />
          </div>
          
          <div class="form-outline mb4 text-center" v-if="is_otp_send">
            <p  class="btn text-center text-dark" @click="sendOTP">Re-Send OTP</p>
          </div>

          <div class="form-outline mb-4 ">
              <button v-if="is_otp_send ? 0: 1" class="btn btn-lg btn-primary w-100"  @click="sendOTP" :disabled="!(this.isEmail() && this.isC24Email())">Send OTP</button>
              <button v-if="is_otp_send" class="btn btn-lg btn-primary w-100" type="button"  @click="loginUser">Verify</button>
          </div>
      </div>
    </div>
  </div>
  </section>
  </template>
  
  <script>


    export default {
      name: 'LoginPage',
      components: {
        //NavBar
      },
      created(){
        this.inputenter()
      },
      data () {
        return {
          username: '',
          email: '',
          otp: '',
          response_otp: '',
          password: '',
          is_otp_send: 0,
          wrongCred: false, // activates appropriate message if set to true
          errors: null,
        }
      },
      methods: {
        loginUser: function(){ // call loginUSer action
          this.errors = null;
          this.wrongCred = false
          this.$store.dispatch('loginUser', {
            email: this.email,
            otp: this.otp
          }).then(() => {
              this.wrongCred = false
              this.$router.push(this.$route.query.redirect || '/')
            
            }).catch(err => {
              console.log(err)
              if (err?.response?.data?.errors){
                this.errors =err?.response?.data?.errors
                this.wrongCred = true 
              }
              else { 
                this.wrongCred = false 
                this.errors = "Network Error"
              }
               // if the credentials were wrong set wrongCred to true
          })
        },
        sendOTP: function(evt){
          this.errors = null;
          this.wrongCred = false
          evt.preventDefault();
          this.$store.dispatch('sendOTP', {
            email: this.email
          })
            .then((response) => {
              this.wrongCred = false
              this.is_otp_send = 1
              //console.log("OTP RESPONSE", response)
              this.response_otp = response.data.otp
          })
          .catch(err => {
              console.log(err)
              this.errors = "Network Error"
              //alert("something goes wrong");
          })
        },
        isNumber: function(evt) {
          //console.log("keypress", evt )
          const charCode = (evt.which) ? evt.which : evt.keyCode;
          if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
              evt.preventDefault()
          } else {
              this.email = evt.target.value
          }
        },
        isEmail: function(){
          const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
          return EMAIL_REGEXP.test(this.email);
        },
        isC24Email: function(){
            const email_part = this.email?.split('@')
            if (email_part?.length === 2){
              if ((email_part[1] === 'cars24.com') || email_part[1] === 'cariotauto.com'){
                return true
              }
            }
            return false
        },
        inputenter: function() {
        const inputs = document.querySelectorAll('#otp > *[id]');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('keydown', function(event) {
                if (event.key === "Backspace") {
                    inputs[i].value = '';
                    if (i !== 0) inputs[i - 1].focus();
                    
                } else {
                    if (i === inputs.length - 1 && inputs[i].value !== '') {
                        return true;
                    } else if (event.keyCode > 47 && event.keyCode < 58) {
                        inputs[i].value = event.key;
                        if (i !== inputs.length - 1) inputs[i + 1].focus();
                        event.preventDefault();
                    } else if (event.keyCode > 64 && event.keyCode < 91) {
                        inputs[i].value = String.fromCharCode(event.keyCode);
                        if (i !== inputs.length - 1) inputs[i + 1].focus();
                        event.preventDefault();
                    }
                }
            });
          }
        }
      }
    }
  </script>
  
<style scoped>
    @import url(https://fonts.googleapis.com/css?family=Quicksand) ;
    .login-form {
      margin: 0;
      padding: 0;
    }
    .lg {
      background-color: #606366;
      text-align: center;
      color: white;
      font-family: 'Quicksand', sans-serif;
      padding: 0;
      margin: 78px 0;
    }
    .login-form .form-control-mob-no {
      word-spacing: 2em;
    }
  .otp-field {
    flex-direction: row;
    column-gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.otp-field input {
  height: 45px;
  width: 42px;
  border-radius: 6px;
  outline: none;
  font-size: 1.125rem;
  text-align: center;
  border: 1px solid #ddd;
}
.otp-field input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.otp-field input::-webkit-inner-spin-button,
.otp-field input::-webkit-outer-spin-button {
  display: none;
}
</style>