<template>
    
<div class="container-fluid min-vh-100">
    <div class="container-lg min-vh-100">
        <div class="row" v-if="this.orders">
          <div class="col-md-8 offset-md-2 mt-4" v-for="orderInfo in this.orders" :key="orderInfo.id">
            <div class="card">
              <div class="card-header text-center">
                <span
                  class="reg_no_text text-center"
                  style="
                    font-size: xx-large;
                    font-weight: 600;
                  "
                  >{{ orderInfo?.asset_details?.reg_no }}</span
                >
              </div>
            
              <div class="card-body">
                <div class="row">
                  
                  <div class="col-md-7">
                    <div class="row">
                      <div class="col-md-12 col-sm-2 text-center">
                        <img class="text-center" height="80" :src="orderInfo?.asset_details?.variant?.model?.brand?.img_path"  />
                      </div>
                      <div class="col-md-12">
                        <div class="text-center">
                          <h5
                            class="text-center mt-4 text-uppercase"
                          >
                            {{ orderInfo?.asset_details.variant.model.display_name }}
                          </h5>
                          <h3>
                            <strong
                              class="badge bg-secondary-blue"
                              >{{ orderInfo?.asset_details.variant.display_name }}</strong
                            >
                          </h3>

                          <h6 class="m-0 p-0">
                            <strong class="text-center text-uppercase" 
                              >{{ orderInfo?.asset_details.variant.year }} | {{ orderInfo?.asset_details.variant.fuel_type }} |
                              {{ orderInfo?.asset_details?.variant?.transmission_type }} | {{ orderInfo?.city}}
                            </strong>
                          </h6>
                          <h4 class="pt-2 text-center"><span class="badge bg-dark border border-bottom-1">
                            ₹ {{ Math.round(orderInfo?.asset_details?.variant?.ex_showroom_price)?.toLocaleString() }}  
                            </span><br/>
                            <strong class="p-0" style="font-size: small;">Ex-Showroom</strong>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                      <div class="text-success">
                        <moving-car></moving-car>
                      </div>
                      <div class="text-center" v-if="orderInfo?.status === 'PENDING' ">
                        <h6 class="text-center p-0 text-dark">
                          <span class="rounded border border-danger p-2 text-danger">APPLICATION PENDING</span>&nbsp;
                        </h6>
                        <br/>  
                        <button :id="orderInfo?.id" :aria-label="orderInfo?.lead?.id" class="btn btn-sm btn-dark" @click="redirect2CompleteOrderPage">Complete your application</button>
                      </div>
                      <div class="text-center" v-if="orderInfo?.status === 'CANCELED' || orderInfo?.status === 'EXPIRED'">  
                        <h6 class="text-small">
                            <span class="rounded border border-danger p-2 text-danger">APPLICATION {{ orderInfo?.status }}</span>&nbsp;
                        </h6>
                      </div>
                      <div class="text-center" v-if="orderInfo?.status === 'PROCESSING'">  
                        <h6 class="text-small">
                            <span class="rounded border border-success p-2 text-success">UNDER PROCESSING</span>&nbsp;
                          </h6>
                          <br/>
                          <button  :id="orderInfo?.id" class="btn btn-primary btn-sm" @click="redirect2OrderDetailsPage">View Order Details </button>
                      </div>
                      <div class="text-center" v-if="orderInfo?.status === 'PROCESSED'">  
                        <h6 class="text-small">
                            <span class="rounded border border-success p-2 text-success">POLICY ISSUED</span>&nbsp;
                          </h6>
                          <br/>
                          <button  :id="orderInfo?.id" class="btn btn-primary btn-sm" @click="redirect2OrderDetailsPage">View Order Details </button>
                      </div>
                      <div class="text-center" v-if="orderInfo?.status === 'CLAIMED'">  
                        <h6 class="text-small">
                            <span class="rounded border border-success p-2 text-success">BUYBACK CLAIMED</span>&nbsp;
                          </h6>
                          <br/>
                          <button  :id="orderInfo?.id" class="btn btn-primary btn-sm" @click="redirect2OrderDetailsPage">View Order Details </button>
                      </div>
                  </div>
                </div>
              </div>
              <!-- <div class="card-footer">
                <div class="text-center">
                  
                </div>
              </div> -->
            </div>
          </div>
        </div>
    </div>
</div>

</template>

<script>
import UserService from "@/service/user.service";
import MovingCar from "@/components/MovingCar.vue";

export default {
    components: { MovingCar },
    name: 'OrderListPage',
    watch: {
        $route: "fetchData",
    },
    data(){
        return {
            error: null,
            orders: null
        }
    },
    created(){
      this.fetchData();
    },
    methods: {
        fetchData: function(){
            UserService.getOrders()
            .then((response)=>{
                this.orders = response.data
            })
            .catch((err)=>{
                this.error = err
            })
        },
        redirect2CompleteOrderPage: function(event) {
          
          this.$router.push({ name: "CompleteOrderPage",  params: {id: event.target.ariaLabel?event.target.ariaLabel:0, order_id:event.target.id } });
        },
        redirect2OrderDetailsPage: function(event) {
          this.$router.push({ name: "OrderDetails",  params: {id: event.target.id } });
        },
        getBgColor: function(status){
          
            if (status === 'PROCESSING'){
                return 'text-success'
            }
            if (status === 'PROCESSED'){
                return 'text-success'
            }
            if (status === 'CLAIMED'){
                return 'text-success'
            }
            return 'text-danger'
        }
    }
}
</script>
<style scoped>
.card > .card-watermark {
  position: relative;
}
.card-watermark .card-watermark-behind {
    border: solid 1px lightgray;
    top: 40%;
    left: 40%;
    letter-spacing: 0.2em;
    text-align: center;
    position:absolute;
    
    /* color:lightgrey; */
    
    transform:rotate(340deg);
    -webkit-transform:rotate(340deg);
}
.card-watermark-text {
  padding: 10px;
  font-size:30px;
}
.card-watermark-success {
  color: hwb(120 75% 11%) !important;
  border: solid 2px  hwb(120 75% 11%);
}

.card-watermark-primary {
  color: lightskyblue;
  border: solid 2px lightskyblue;
}

.card-watermark-light {
  color: lightgray;
  border: solid 2px lightgray;
}

.card-watermark-danger {
  color: #f7bbbb;
  border: solid 2px #f7bbbb;
}

</style>
