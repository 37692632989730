<template>
  <nav class="navbar navbar-expand-lg  bg-body-tertiary top-nav">
    <div class="container-lg">
      <a href="#" class="navbar-brand">
        <router-link to="/" class="nav-link text-center" aria-current="page">
          <img width="80" height="39" style="width:80;height:39" src="./assets/cars-logo.png" alt="logo">
        </router-link>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-center w-100" id="navbarSupportedContent">
        <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0 justify-content-center text-center w-100">
          <li class="nav-item">
            <a href="tel:9873225791" class="btn text-nowrap text-dark p-2">
              <span style="font-weight: 800; letter-spacing: 0.1em;"><i class="bi bi-telephone-plus-fill"></i> 9873225791</span>&nbsp;
            </a>
          </li>
          
        </ul> -->
        <div class="navbar-collapse justify-content-end text-center" id="id_navbar">
        <ul class="navbar-nav " v-if="isAuthenticated()">
          <li class="nav-item">
                          <!-- <a
                            href="tel:9873225791"
                            class="btn btn-sm btn-danger mt-1"
                            ><i class="bi bi-telephone-fill"></i> +91
                            9873225791</a
                          > -->
                          <a
                            href="mailto:buyback.newcars@cars24.com"
                            class="btn text-nowrap"
                            style="font-weight: 600;"
                            >
                            <i class="bi bi-envelope-fill"></i>
                            buyback.newcars@cars24.com</a
                          ></li>

          <li class="nav-item dropdown">
            <navbarAccount :username="getUserName()" />
          </li>
        </ul>
      </div>
      </div>
      
    </div>
  </nav>
  
  <router-view class="min-vh-100" />

</template>


<script>
// import HelloWorld from './components/HelloWorld.vue'
import EventBus from "./common//EventBus";
import navbarAccount from "./components/navbarAccount.vue";

export default {
  name: 'App',
  components: {
    //NavHeader
    navbarAccount
  }, 
  created(){
      this.isAuthenticated()
  },  
  methods: {
     get_date: function() {
        return new Date().toDateString('Y');
     },
     getUserName: function() {
        if (this.$store.getters.isLoggedIn){
          return this.$store.getters.username
        }
     },
     isAuthenticated: function() {
        //console.log('this.$store.getters.isLoggedIn', this.$store.getters.isLoggedIn)
        return this.$store.getters.isLoggedIn
    },
    logOut() {
      this.$store.dispatch('loginUser');
      this.$router.push('/login');
    }
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeUnmount() {
    EventBus.remove("logout");
  }
}
</script>

<style>

body {
  margin: 0px;
  background-color: #F4F5F7;
  color: #465166;
  font-size: 14px;
  font-family:  "Poppins,sans-serif";
  -webkit-font-smoothing:antialiased;
}

.container-fluid {
  /* padding-left: 10em ;
  padding-right: 10em ;  */
  width: 100%;
  background-color: transparent;
}
.container {
  background-color: #fff !important;
}
/* primary color #f55e61 */

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* font-family:  "Noto Sans Display", sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

.top-nav {
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px rgba(122, 1, 9, 0.1);
  /* position: sticky;
  width: 100%;
  margin: 0;
  padding-top: 1em;
  padding-bottom: 1em;  
  align-items:center;
  height: 80px; */
}



@media(max-width: 600px) {
  .img-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    
    /* text-align: center;
    display: flex;
    align-items: center;
    widows: 100%; */
  }
}

.search-box {
  display: flex;
  align-items: center;
  /* width: 70% !important; */
}
.search-box .fas {
  position: absolute;
  margin-left: 8px, 8px, 8px, 32px;
}
.search-box input[type="search"] {
  padding: 8px;
}

.scroll-vertical  {
    overflow-y: scroll !important;
}
.text-silver {
  color: #999;
}

.result-header-wrap {
  padding-top: 1em;
  padding-bottom: 1em;
}

.footer, .footer-container {
  background-image: linear-gradient(to bottom,  rgb(250, 50, 50), #f55e61);
  color: #fff;
}

@media(max-width: 600px) {
  .footer-container > .row > .col-3   {
    overflow: auto;
  }
}

.footer-links > li > a {
  color: #fff;
  text-decoration: none;
}

.footer-links > li {
    line-height: 2em;
}

.footer-links > li:hover {
    font-weight: bold;
}
.line-height-normal {
  line-height:normal !important
}
.btn-primary, .btn-primary:hover {
  background-color: #ef6e0b;
  border-color:#f55e61;
  border-color: #ef6e0b;
  letter-spacing:.01em;
  font-weight:600;
  --bs-btn-bg: #ef6e0b;
  --bs-btn-border-color: #fe6e00;
  --bs-btn-hover-border-color:#fe6e00;
  --bs-btn-active-border-color:#fe6e00;
  --bs-btn-disabled-border-color:#fe6e00;
  --bs-btn-active-bg:#fe6e00;
  --bs-btn-disabled-bg: hsl(26, 100%, 66%);
  
}

.form-control {
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
input.form-control:focus { 
    outline: none !important;
    border-color: #ef6e0b;
    box-shadow: 0 0 2px #ef6e0b;
 }

 .bg-primary {
   background-color: #ef6e0b;
 }
.bg-secondary-blue {
  background-color: #0c72c6 !important;
}
.text-blue {
  color: #0c72c6 !important;
}
 /* .router-link-active{
   border-bottom: #ef6e0b solid 2px;
 } */
.nav-link {
  font-weight: 600;
  color: black;
}

.text-primary {
  color:  #ef6e0b !important;
}

.text-muted {
  color:lightgrey !important
}

</style>
