<template>
    <div class="container-fluid min-vh-100">
        <div class="container-lg min-vh-100">
            <div class="row" style="max-height: 540;overflow-y: scroll;" >
                <div class="col-md-6 offset-md-3">
                    <h2 class="text-center pt-4 pb-4">Upload Document Here</h2>
                    
                    <div class="mb-4 text-center" v-if="this.error">
                        <span class='text-danger'><strong>{{ this.error  }}</strong></span><br/>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Upload RC Copy</label>
                        <input class="form-control" type="file" placeholder="upload RC" @change="uploadRC" ref="file" >
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Upload Insurance Copy</label>
                        <input class="form-control" type="file" placeholder="upload Insurance" @change="uploadFile" ref="file">
                    </div>
                </div>
            </div>
            <div class="row py-3" style="position: relative;">
                <div class="col-md-6 offset-md-3 " style="position: absolute; top:550px">
                    <br/>
                    <button class="btn btn-primary btn-lg float-start">BACK</button>
                    <button class="btn btn-primary btn-lg float-end" @click="submit">Submit</button>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'UploadDocPage',
    data: () => ({rc: null,insurance:null, error: null}),
    methods: {
        uploadRC(event) {
            this.rc = event.target.files[0];
        },
        uploadInsurance(event) {
            this.insurance = event.target.files[0];
        },
        submit(){
            this.error = null;
            if (!this.rc){
                this.error = "please upload files"
            }
            if (!this.rc){
                this.error = "please upload files"
            }
            if (!this.error){
                alert(" Done!, we'll reach out soon")
                
            }
        }
    }
}
</script>

<style>

</style>