import { createWebHistory, createRouter } from "vue-router"
import LoginPage from "@/views/LoginPage.vue"
import HomePage from "@/views/HomePage.vue"
import BuybackPage from "@/views/BuybackPage.vue"
import TncPage from "@/views/TncPage.vue"
import OrderDetailsPage from "@/views/OrderDetailsPage.vue"
import OrderListPage from "@/views/OrderListPage.vue"
import PageNotFound from "@/views/PageNotFound"
import UploadDocPage from "@/views/UploadDocPage"
import RateCardPage from "@/views/RateCardPage"
import UserBuybackPage from "@/views/protected_views/UserBuybackPage"
//import UserCompleteOrderPage from "@/views/protected_views/UserCompleteOrderPage"
import store from "../store"
//import { createAuthGuard } from "@auth0/auth0-vue";


const routes = [
  {path: "/", name: "Home", component: HomePage, meta: {is_protected: true}},
  {path: "/login", name: "Login", component: LoginPage},
  {path: "/logout", name: "logout", beforeEnter (to, from, next) {
    store.dispatch('logoutUser').then(() =>{
        return next({'name' : 'Login'});
      }).catch(err =>{
        console.log(err)
        return next({'name' : 'Login'});
      })
    }
  },
  {path: "/rate/card", name: "RateCard", component: RateCardPage, meta: {is_protected: false}},
  {path: "/order/:id/tnc", name: "Tnc", component: TncPage, meta: {is_protected: true}},
  {path: "/order-details/:id", name: "OrderDetails", component: OrderDetailsPage, meta: {is_protected: true}},
  {path: "/upload/doc", name: "UploadDoc", component: UploadDocPage},
  {path: "/buyback/:id", name: "Buyback", component: BuybackPage, meta: {is_protected: true}},
  {path: "/buyback/:id/order/:order_id", name: "CompleteOrderPage", component: BuybackPage, meta: {is_protected: true}},
  {path: "/user/buybacks", name: "", component: UserBuybackPage, meta: {is_protected: true}},
  {path: "/user/orders", name: "OrderList", component: OrderListPage, meta: {is_protected: true}},
  {path: '/:catchAll(.*)*', name: "PageNotFound", component: PageNotFound},
  
]


const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
  if (to.name == 'Login' ){
    if (store.getters.isLoggedIn){
      return next({ name: 'Home' });
    }
    next()
    return
  }
  if (to.matched.some(record => record.meta.is_protected)) {
    if (!store.getters.isLoggedIn) {
        next({ name: 'Login', query: {'redirect': from.path} })
    } 
    else {
      // if (!store.getters.isVerified){
      //   console.log("verifing token");
      //   store.dispatch('verifyToken').then(() => {
      //     console.log("verified")
      //   }).catch(err => {
      //     console.log(err); 
      //     next({ name: 'Login', query: {'redirect': from.path} })
      //   })
      // }
      // else {
      //   console.log("already verified")
      //   next() 
      // }
      next() 
    }
  } 
  else {
    next() 
  }
});

export default router