import api from './api';

class UserService{
    getStates(){
        return api.get("/api/states")
    }

    createLead(payload){
        console.log(payload)
        return api.post(
            `/api/buyback/leads/0`, 
            payload
        )
    }
    getLeads(){
        return api.get("/api/buyback/leads")
    }
    getOrders(){
        return api.get("/api/buyback/orders/all")
    }
    createOrders(lead_id, payload){
        return api.post(
            `/api/buyback/leads/${lead_id}/order`,payload
        )
    }
    updateOrders(order_id, payload){
        return api.post(
            `/api/buyback/orders/${order_id}`,payload
        )
    }
    submitOrders(order_id){
        return api.post(
            `/api/buyback/orders/${order_id}/submit`
        )
    }
    getOrderDetails(order_id){
        return api.get(
            `/api/buyback/orders/${order_id}`
        )
    }
    getRateCard(variant_id, city){
        return api.get(
            `/api/buyback/rate/card/variant/${variant_id}/city/${city}`
        )
    }
}

export default new UserService();