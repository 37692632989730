import { createStore } from 'vuex';
import api from "@/service/api";
//import { axiosBase } from '@/service/index'

import { Buffer } from 'buffer';



const store = createStore({
  state: {
    accessToken: localStorage.getItem('access_token') || null, // makes sure the user is logged in even after
    // refreshing the page
    refreshToken: localStorage.getItem('refresh_token') || null,
    user:  localStorage.getItem('info') ? JSON.parse(Buffer.from(localStorage.getItem('info'), 'base64').toString('ascii')) : null,
    APIData: '', // received data from the backend API is stored here.
    reg_no: null,
    reg_date: null,
    make: {},
    model: {},
    variant: {},
    year: null,
    fuel_type: null,
    odo: null,
    transmission_type: null,
    city: null,
    buybackTerms: null,
    api_variants: null,
    lead: null,
    EligibleVariants: null,

  },
  getters: {
    isLoggedIn (state) {
      return state.accessToken != null
    },
    username: (state) => {return state.user ? state.user.username || state.user.mob_no: null},
    user: (state) => state.user,
    isVerified: (state) => state.user ? true: false
  },
  mutations: {
    updateLocalStorage (state, { access, refresh, user }) {
      localStorage.setItem('access_token', access)
      localStorage.setItem('refresh_token', refresh)
      localStorage.setItem('info', Buffer.from(JSON.stringify(user)).toString('base64') )
      state.accessToken = access
      state.refreshToken = refresh
      state.user = user
    },
    updateAccess (state, access) {  
      state.accessToken = access
      localStorage.setItem('access_token', access)
    },
    destroyToken (state) {
      state.accessToken = null
      state.refreshToken = null
      state.user = null
    },
    destroyData(state){
      state.reg_date = null
      state.make = {},
      state.model = {},
      state.variant = {},
      state.year = null,
      state.fuel_type = null,
      state.odo = null,
      state.transmission_type = null,
      state.buybackTerms = null,
      state.api_variants = null
    },
    setUser(state, userinfo){
      state.user = userinfo.user
    },
    updateBuybackTerms(state, terms){
      state.buybackTerms = terms
    },
    changeMake(state, payload){
      state.make = payload.make
    },
    changeRegNo(state, payload){
      state.reg_no = payload.reg_no
    },
    changeYear(state, payload){
      state.year = payload.year
    },
    changeModel(state, payload){
      state.model = payload.model
    },
    changeTransmission(state, payload){
      state.transmission_type = payload.transmission_type
    },
    changeFuel(state, payload){
      state.fuel_type = payload.fuel_type
    },
    changeVariant(state, payload){
      state.variant = payload.variant
    },
    updateApiVariants(state, payload){
      state.api_variants = payload.data
    },
    changeOdo(state, payload){
      state.odo = payload.odo
    },
    changeCity(state, payload){
      state.city = payload.city
    },
    changeRegDate(state, payload){
      state.reg_date = payload.date
    },
    changeLead(state, payload){
      state.lead = payload.lead
    },
    updateEligibleVariants(state, variant_list){
      state.EligibleVariants = variant_list
    },
  },
  actions: {
    refreshToken (context) {
      return new Promise((resolve, reject) => {
        api.post('/api/auth/token/refresh/', {
          refresh: context.state.refreshToken
        }) // send the stored refresh token to the backend API
          .then(response => { // if API sends back new access and refresh token update the store
            //console.log('New access successfully generated')
            context.commit('updateAccess', response.data.access)
            //context.commit('setUser', response.data)
            resolve(response)
          })
          .catch(err => {
            console.log('error in refreshToken Task')
            reject(err) // error generating new access and refresh token because refresh token has expired
          })
      })
    },
    verifyToken (context) {
      return new Promise((resolve, reject) => {
        // send the username and password to the backend API:
        api.post('/api/auth/token/verify/', {
          token: context.state.accessToken
        })
        // if successful update local storage:
          .then(response => {
            //console.log('token successfully verified')
            context.commit('setUser', response.data)
            resolve(response)
          })
          .catch(err => {
            //console.log('error in  verifing token')
            context.commit('setUser', {user: null})
            reject(err)
          })
      })
    },
    registerUser (context, data) {
      return new Promise((resolve, reject) => {
        api.post('/register/', {
          name: data.name,
          email: data.email,
          username: data.username,
          password: data.password,
          confirm: data.confirm
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logoutUser (context) {
      if (context.getters.isLoggedIn) {
        return new Promise((resolve, reject) => {
          api.post('/api/auth/token/logout/',{
              refresh: context.state.refreshToken
            })
            .then(response => {
              localStorage.removeItem('access_token')
              localStorage.removeItem('refresh_token')
              localStorage.removeItem('info')
              context.commit('destroyToken')
              //console.log(response)
              resolve(response)
            })
            .catch(err => {
              localStorage.removeItem('access_token')
              localStorage.removeItem('refresh_token')
              localStorage.removeItem('info')
              context.commit('destroyToken')
              resolve(err)
              console.log(reject)

            })
        })
      }
    },
    loginUser (context, credentials) {
      return new Promise((resolve, reject) => {
        // send the username and password to the backend API:
        api.post(`/api/auth/token/obtain/`, {
          //username: credentials.username,
          //password: credentials.password
          email: credentials.email,
          otp: credentials.otp
        })
        // if successful update local storage:
          .then(response => {
            context.commit('updateLocalStorage', { access: response.data.access, refresh: response.data.refresh, user:response.data.user }) // store the access and refresh token in localstorage
            context.commit('setUser', response.data)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    sendOTP (context, credentials) {
      return new Promise((resolve, reject) => {
        // send the username and password to the backend API:
        api.get(`/api/auth/send-otp?email=${credentials.email}`)
        // if successful update local storage:
          .then(response => {
              //console.log(response)
              resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getBuybackTerms(context, attrs){
        return new Promise((resolve, reject) => {
          api.get(`/api/buyback-terms/${attrs.variant_id}/city/${attrs.city}`)
          .then(response => {
              //console.log(resolve.data)
              context.commit('updateBuybackTerms', response.data)
              resolve(response)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    getBuybackVariants(context){
      return new Promise((resolve, reject) => {
        api.get(`/api/buyback/variants/`)
        .then(response => {
            console.log("getBuybackVariants", response.data)
            context.commit('updateEligibleVariants', response.data)
            resolve(response)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
    }
  }
})


export default store
