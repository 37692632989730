<template>
    
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown"  data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://assets.cars24.com/production/c2b-website/231006155227/js/1dbd974090c35e48bdb8c3273b07fb4e.svg" /> <strong>Account</strong>
    </a>
    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" style="min-width:300px;margin-top: 12px;">
        <li class="nav-item p-2 pb-0">
            <div class="w-100">
                
                <router-link to="/logout" class="btn btn-primary w-100" role="button">Logout</router-link>
                
            </div>
            <div class="w-100">
                <h6 class="text-center pt-4 pb-0">Welcome Back </h6>
                <p class="text-center pt-0"><small>{{ username}} </small></p>
                <!-- <a class="nav-link text-center" href="">Update Profile</a> -->
            </div>
            <div class="w-100 pt-4">
                <div class="d-flex gap-2">
                    <div class="col bg-light text-center">
                        <a class="nav-link" href="#" @click="this.$router.push({ name: 'OrderList' })">
                            <img src="https://assets.cars24.com/production/c2b-website/231006155227/js/1b80d7ef9155284e80646e52afa92e74.svg" /> <br/>
                            <span style="font-weight:600;">
                                <small>Orders</small>
                            </span>
                        </a>
                    </div>    
                    <!-- <div class="col bg-light text-center">
                        <a class="nav-link" href="text-danger">
                            <img src="https://assets.cars24.com/production/c2b-website/231006155227/js/bc59b987ded237b764d2efd6f829614a.svg" /><br/><strong><small>Claims</small></strong>
                        </a>
                    </div>  -->
                </div> 
            
            </div>
        </li>
    </ul>

</template>

<script>
export default {
    props: {
        username: String
    },
    data(){
        return {
            active: false
        }
    },
    computed: {
        
    },
    methods: {
        toggle: function() {
            // alert("sadasd");
            this.active = !this.active
        },
        Logout: function() {
            alert("logout successfully");
            this.$store.dispatch('logoutUser', ()=>{
                this.$route.push({'name': 'Login'})
            })
        }
    }
}


</script>