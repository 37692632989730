<template>
    <div class="container-fluid min-vh-100">
        <div class="container-lg min-vh-100">
            <h2 class="mt-5 text-center"> Rate Card Search</h2>
            <div class="row g-3">
                <div class="col-md-6 offset-3">
                    <label class="form-label">Search Rate Card</label>
                    <div class="input-group">
                    <input type="text" class="form-control form-control-lg" placeholder="eg Bangalore_v1_Q3_2024_376422" v-model="rate_card_id" />
                    <div class="input-group-text btn btn-primary btn-lg" @click="fetchData">Submit</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 offset-3" v-if="rateCards ? !rateCards.length ? 1 : 0 : 0 ">
                <h6 class="text-danger mt-5 text-center">No Result found</h6>
            </div>
            <div class="col-md-6 offset-3" v-if="rateCards ? rateCards.length: 0 ">
                <div class="text-center mt-5">
                    <h2 class="text-center"><span class="badge bg-success"> {{ variant }}</span> </h2>
                    <hr />
                
                    <h4 class="m-0 p-0"><strong class="text-center"> {{ MfgYear }} | {{ Fuel }} | {{ TransmissionType }} | <span class="text-secondary">₹ {{ Number(exShowroomPrice).toLocaleString() }}</span>  </strong></h4>
                </div>
            </div>
            <div class="col-md-12" v-if="rateCards ? rateCards.length ? 1 : 0 : 0 ">
                <div class="table-responsive mt-5 border p-3">
                    <h6>Future Assured Buyback Grid</h6>
                    <table class="table">
                        <thead>
                            <tr><th></th><th class="text-center" colspan="8">Time since date of sale</th>
                            </tr>
                            <tr><th >Mileage/Year</th>
                                <th class="text-end" v-for="item in getOdoRank1" v-bind:key="'th_'+item.id">{{ item.tenure_label     }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><th>{{ getOdoRank1 ? getOdoRank1[0].odometer_label : '' }}</th>
                                <td class="text-end text-nowrap" v-for="item in getOdoRank1" v-bind:key="item.id">₹ {{ Number(item.dep_on_road_price).toLocaleString() }}</td>
                            </tr>
                            <tr><th> {{ getOdoRank2 ? getOdoRank2[0].odometer_label: '' }}</th>
                                <td class="text-end text-nowrap" v-for="item in getOdoRank2" v-bind:key="item.id">₹ {{ Number(item.dep_on_road_price).toLocaleString() }}</td>
                            </tr>
                            <tr><th> {{ getOdoRank3 ? getOdoRank3[0].odometer_label : '' }}</th>
                                <td class="text-end text-nowrap" v-for="item in getOdoRank3" v-bind:key="item.id">₹ {{ Number(item.dep_on_road_price).toLocaleString() }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/service/api";

export default {
    name: "RateCardPage",
    setup() {
    },
    data(){
        return {
            rate_card_id: null,
            rateCards: null,
        }
    },
    computed: {
        getOdoRank1(){
            return this.getODOByRank(1)
        },
        getOdoRank2(){
            return this.getODOByRank(2)
        },
        getOdoRank3(){
            return this.getODOByRank(3)
        },
        variant(){
            return this.rateCards[0].blf_mmv
        },
        exShowroomPrice(){
            return this.rateCards[0].c24_variant.ex_showroom_price
        },
        MfgYear(){
            return this.rateCards[0].c24_variant.year
        },
        Fuel(){
            return this.rateCards[0].c24_variant.fuel_type
        },
        TransmissionType(){
            return this.rateCards[0].c24_variant.transmission_type
        }
    },
    methods: {
        fetchData(){
            api.get(
                `/api/buyback/rate/card/search/${ this.rate_card_id }/`
            ).then(response => {
                
                if (!response.data.errors){
                    this.rateCards = response.data
                }
                else{
                    //alert("no result found")
                    this.rateCards = []
                }
                
            }).catch(err =>{
                console.log(err);
            })
        },
        getODOByRank(rank) {
            return this.rateCards.filter((item) => item.odometer_rank === rank );
        }

    }

}
</script>