<template>
    <h3 class="text-center pb-1">Enter your car number</h3>
    <input type="text" maxlength="30" autocomplete="off" placeholder="Eg. DL11CC1234" class="form-control form-control-lg text-center" v-model="reg_no" @change="updateRegno">
    <p class="form-tex text-danger text-center" v-if="!reg_no">Please enter registration number</p>
    <div class="mt-1">
        <hr />
        <h5 class="text-center">Select your car brand</h5>
        <ul class="row list-inline">
            <li class="col-sm-3" v-for="make in popular_makes" v-bind:key="make" >
                <div v-bind:class="(make.make_id === this.brand?.make_id) ?  'text-center brand-img bg-warning': 'text-center brand-img bg-light'">
                    <img v-bind:src="make.logo" v-bind:alt="make.make_display" @click="updateMake" v-bind:id="make.make_id">
                </div>
            </li>
        </ul>
        <!-- <p class="form-tex text-danger text-center" v-if="!brand">please select brand</p> -->
    </div>
</template>

<script>
import { c24Service } from "@/service";

export default {
  name: 'RegNoSelection',
  props: {
    //header: String,
    //title: String,
    disbaled: Boolean,

  },
  emits: [
    "run-validation",
    "toggle-favorite"
  ],
  data() {
    return {
        makes: null,
        popular_makes: null,
        reg_no: null, //this.$store.state.reg_no,
        brand: null //this.$store.state.make,      
    }
  },
  created() {
        this.fetchData()
  },
  methods: {
    fetchData(){
        this.error = null
        this.brand = null,
        //console.log('this.brand = this.$store.state.make || null', this.$store.state.make || null)
        c24Service.getMakes().then(response => {
            this.makes = response.data.detail
            //console.log(this.makes)
            this.popular_makes = response.data.detail.filter((make) => [15,20,21,34].indexOf(make.make_id) >= 0 )
            //this.popular_makes = this.makes.filter((make) => make.is_popular == 'yes').slice(0,-3)
            this.loading = false
        }).catch(e => {
            console.log(e)
            this.error = e
            this.loading = false
        })
        
    },
    get_date: function() {
        return new Date().toDateString('Y');
    },
    updateRegno: function() {
        
        this.$store.commit("changeRegNo", {
            reg_no: this.reg_no,
        });
        this.$emit('run-validation', {
            data:this.get_data(),
            is_valid: this.isValid()
        }) 
        //this.$emit('selectData', valid_data);   
    },
    getBrand: function(brand_id) {
        return this.makes.filter((make)=> make.make_id.toString() === brand_id)[0]
    },
    updateMake: function(event) {
        //if(event.target.ariaDisabled !== 'true'){
        this.brand = this.getBrand( event.target.id);
        //console.log("getBrand", this.brand)
        this.$store.commit("changeMake", {
            make: this.getBrand( event.target.id),
        });

        this.$emit('run-validation', {
            data:this.get_data(),
            is_valid: this.isValid()
        })
        
    },
    
    get_data(){
        return {
            reg_no: this.reg_no,
            brand: this.brand,
        }
    },
    isValid(){
        
        if (this.reg_no && this.brand && this.brand.make_id){
            return true
        }
        return false

    } 
  }
    
}
</script>