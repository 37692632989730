<template>
  <!-- <NavHeader v-bind="{ title: channel.title, 'header': 'VELOX' }" /> -->
  <div class="container-fluid min-vh-100">
    <div class="container-lg min-vh-100">
      <!-- <div class="row pt-4">
        <div class="col-md-12">
          <h4 class="text-left">
            Choose the buyback offer that’s right for you
          </h4>
          <hr />
          <p class="text-left">
            Secure your brand new car's future. Get the best price
          </p>
        </div>
        <div class="col-md-12">
          <h2 v-if="!this.terms || !this.terms.length" class="text-danger m-4">
            Buyback offer Comming Soon for your vehicle...
          </h2>
        </div>
      </div> -->

      <div class="row pt-3">
        <div class="col-md-7 mb-3">
          <div class="card" style="height:330px;">
            <div class="card-header text-center">
              <h4
                class="reg_no_text text-center"
                >{{ this.lead?.reg_no }}</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-sm-2 text-center">
                  <img class="text-center" height="50" v-bind:src="Logo" />
                </div>
                <div class="col-md-12">
                  <div class="text-center">
                    <h5
                      class="text-center mt-4 text-uppercase"
                    >
                      {{ ModelName }}
                    </h5>
                    <h3>
                      <strong
                        class="badge bg-secondary-blue"
                        >{{ VariantName }}</strong
                      >
                    </h3>

                    <h6 class="m-0 p-0">
                      <strong class="text-center text-uppercase"
                        >{{ Year }} | {{ FuleType }} | {{ TransmissionType }}  | {{ this.lead?.city }}
                      </strong>
                    </h6>
                    <h4 class="pt-2 text-center"><span class="badge bg-dark border border-bottom-1">
                       ₹ {{ ex_showroom_price?.toLocaleString() }}  
                      </span>
                      <br/>
                      <strong class="p-0" style="font-size: small;">Ex-Showroom</strong>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 mb-2 d-md-block d-none">
          <div class="card"  style="height:330px;">
            <div class="card-header">
              <h4 
                class="text-center text-uppercase"
              >Buyback Cover Charge</h4>
            </div>
              <div class="card-body  h-100">
                <div class="row h-100">
                  <div class="col-md-12 h-100"> 
                    <div class="text-center">
                      <h5 class="text-danger text-center pt-4">
                          <span class="badge bg-dark"> Flat 50% Discount</span>
                      </h5>
                      <h2 class="text-center">
                        
                        <del>Price: ₹ {{  Math.round(computedCoverCharge,0).toLocaleString() }}</del><br/>
                         <span class="text-success"><strong>₹ {{ Math.round((computedCoverCharge * 0.5),0).toLocaleString() }}</strong></span>
                      </h2>
                      <br/>
                      <button class="btn btn-primary"  @click="btnBuyNow" v-if="this.rate_card">Checkout</button>

                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>


      <div class="row d-none d-md-block" v-if="shall_proceed">
        <div class="col-md-12 mb-2">
          <div class="card">
            <div class="card-body">
              <div class="col-md-12 pt-3">
                <div class="row g-3">
                  <div class="col-md-4 offset-md-1">
                    <label for="staticEmail" class="visually-hidden">Email</label>
                    <input type="text" readonly class="form-control" id="staticEmail2" :value="this.email?this.email: 'email@email.com'">
                  </div>
                  <div class="col-md-4">
                    <label for="inputPassword2" class="visually-hidden">mob_no</label>
                    <input type="text" class="form-control" id="inputPassword2" placeholder="mobile number" v-model="mob_no" maxlength="10">
                    <p v-if="this.mob_no && !isValidMobile" class="text-danger text-center">check phone number</p>
                  </div>
                  <div class="col-md-2 text-center">
                    <button  class="btn btn-primary mb-3" @click="Proceed">Proceed</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-2">
          <div class="card">
            <!-- <div class="card-header p-3">
              <h6 class="card-title text-center">
                Future Assured Buyback Grid
              </h6>
            </div> -->
            <div class="card-body">
              <div class="table-responsive" v-if="this.rate_card">
                <table class="table">
                  <thead class="table-primary">
                      <tr class="table-group-divider">
                          <th></th>
                          <th :colspan="this.rate_card[0].values.length" class="text-center">
                              <strong>Rate Card</strong>[Minimum Assured Price (in lacs (incl. of all taxes)]
                          </th>
                      </tr>
                      <tr>
                          <th rowspan="2" class="align-middle">Mileage / Year (Avg)*</th>
                          <th :colspan="this.rate_card[0].values.length" class="text-center">Months from the date of purchase</th>
                      </tr>
                      <tr>
                          <th class="text-end" v-for="fields in this.rate_card[0].values" v-bind:key="fields.tenure_label">{{ fields.tenure_label }}</th>
                      </tr>
                  </thead>
                  <tbody class="table-group-divider">
                      <tr v-for="item in this.rate_card" v-bind:key="item.label">
                          <th>{{ item.label }}</th>
                            <td class="text-end" v-for="rate in item.values" v-bind:key="rate.tenure_label">
                              {{ (rate.dep_on_road_price / 100000).toFixed(2) }}
                            </td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row d-block d-md-none">
        <div class="col-md-5 mb-2">
          <div class="card"  style="height:330px;">
            <div class="card-header">
              <h4 
                class="text-center text-uppercase"
              >Buyback Cover Charge</h4>
            </div>
              <div class="card-body  h-100">
                <div class="row h-100">
                  <div class="col-md-12 h-100"> 
                    <div class="text-center">
                      <h5 class="text-danger text-center pt-4">
                          <span class="badge bg-dark"> Flat 50% Discount</span>
                      </h5>
                      <h2 class="text-center">
                        
                        <del>Price: ₹ {{ computedCoverCharge.toLocaleString() }}</del><br/>
                         <span class="text-success"><strong>₹ {{ (computedCoverCharge * 0.5).toLocaleString() }}</strong></span>
                      </h2>
                      <br/>
                      <button class="btn btn-primary"  @click="btnBuyNow" v-if="this.rate_card">Checkout</button>

                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        
        <div class="col-md-12 mb-2"  v-if="shall_proceed">
          <div class="card">
            <div class="card-body">
              <div class="col-md-12 pt-3">
                <div class="row g-3">
                  <div class="col-md-4 offset-md-1">
                    <label for="staticEmail" class="visually-hidden">Email</label>
                    <input type="text" readonly class="form-control" id="staticEmail2" :value="this.email?this.email: 'email@email.com'">
                  </div>
                  <div class="col-md-4">
                    <label for="inputPassword2" class="visually-hidden">mob_no</label>
                    <input type="text" class="form-control" id="inputPassword2" placeholder="mobile number" v-model="mob_no" maxlength="10">
                    <p v-if="!isValidMobile" class="text-danger text-center">check phone number</p>
                  </div>
                  <div class="col-md-2 text-center">
                    <button  class="btn btn-primary mb-3" @click="Proceed">Proceed</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div class="row mt-3 border p-3" style="height: 200px" v-if="error">
        <div class="col-md-12">
          <div class="overflow-scroll">
            <h6 class="text-danger"><strong>Error Description:</strong></h6>
            <p class="text-danger">{{ error }}</p>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-8">
          <div class="float-lg-start mt-5">
            <button class="btn btn-lg btn-primary" @click="goBack">Back</button>
          </div>

          <div class="float-lg-end mt-5" v-if="selectedTerm">
            <button class="btn btn-lg btn-primary" @click="Proceed">
              Proceed
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
//import slider from "vue3-slider"
import api from "@/service/api";
import UserService from "@/service/user.service";


export default {
  name: "BuybackPage",
  components: {
    //"vue3-slider": slider,
  },
  mounted() {
    this.animatedCard = 0;
    // Automatically switch between sliding cards every 5 seconds
    setInterval(() => {
      this.animatedCard = (this.animatedCard + 1) % 4;
    }, 1000);
  },
  data() {
    return {
      months: 24,
      odo_index: 2,
      animatedCard: 0,
      selectedCard: 1,
      loading: false,
      car_info: null,
      error: null,
      selectedTerm: null,
      lead: null,
      rate_card:null,
      coverage_price: null,
      on_road_price: null,
      ex_showroom_price: null,
      shall_proceed: null,
      order_id: null,
      email: null,
      mob_no: null,
    };
  },
  computed: {
    leadId() {
      return this.$route.params.id;
    },
    orderId() {
      return this.$route.params?.order_id;
    },
    Logo() {
      return this.$store.state.lead
        ? this.$store.state.lead.variant.model.brand.img_path
        : "";
    },
    MakeName() {
      return this.$store.state.lead
        ? this.$store.state.lead.variant.model.brand.display_name
        : "";
    },
    ModelName() {
      return this.$store.state.lead
        ? this.$store.state.lead.variant.model.display_name
        : "";
    },
    VariantName() {
      return this.$store.state.lead
        ? this.$store.state.lead.variant.display_name
        : "";
    },
    FuleType() {
      return this.$store.state.lead
        ? this.$store.state.lead.variant.fuel_type
        : "";
    },
    TransmissionType() {
      return this.$store.state.lead
        ? this.$store.state.lead.variant.transmission_type
        : "";
    },
    Year() {
      return this.$store.state.lead ? this.$store.state.lead.variant.year : "";
    },
    computedCoverCharge() {
      return Math.round(this.coverage_price);
    },
    getKmDriven() {
      let kms = 0;
      switch (this.odo_index) {
        case 1:
          kms = 10000;
          break;
        case 2:
          kms = 15000;
          break;
        case 3:
          kms = 20000;
          break;
        default:
          break;
      }
      return kms;
    },
    isValidMobile(){
      const MOB_NO_REG_EXP = /^[6-9]\d{9}$/;
      return MOB_NO_REG_EXP.test(this.mob_no)
    }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  setup() {
    const activeTabId2 = ref("ex2-1");
    return {
      activeTabId2,
    };
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
  methods: {
    fetchData() {
      //this.car_info = this.$store.state.variant
      this.order_id = null;
      this.shall_proceed = false;
      this.loading = true;
      this.rate_card = null
      if (!this.leadId) {
        return;
      }
      api.get(`/api/buyback/leads/${this.leadId}`).then((response) => {
        const lead = response.data ? response.data.data : null;
        //this.lead = lead
        console.log(lead);
        if (lead && !lead.error) {
          this.$store.commit("changeCity", {
            city: { city: lead.city },
          });
          this.$store.commit("changeLead", {
            lead: lead,
          });
          this.lead = this.$store.state.lead;
          //this.city = this.$store.state.lead
          UserService.getRateCard(this.lead.variant.id, this.lead.city)
            .then((response) => {
              if (!response.data.errors){
                this.rate_card = response.data.rate_card
                this.coverage_price = response.data.coverage_price
                this.on_road_price = response.data.on_road_price
                this.ex_showroom_price = response.data.ex_showroom_price
                // console.log("this.rate_card", this.rate_card)
              }
              
              //this.terms = this.$store.state.buybackTerms;
              //console.log("this.terms", this.terms);
              //this.selectTerm();
              //console.log("this.terms", this.terms);
              //(this.odo_index = this.$store.state.odo
              //  ? this.$store.state.odo.index
              //  : 2),
              //  (this.loading = false);
            });

          // console.log(
          //   "all=>",
          //   this.$store.state.make,
          //   this.$store.state.model,
          //   this.$store.state.variant
          // );
        } else alert("something is wrong");
      });

      //this.model = {...this.$store.state.model, make: this.$store.state.make}
      this.error = this.car_info = null;
      this.car_info = {
        ...this.$store.state.variant,
        model: { ...this.model },
        fuel: this.$store.state.fule_type,
        transmission: this.$store.state.transmission_type,
        year: this.$store.state.year,
      };
      console.log("this.car_info", this.car_info);
    },
    selectTerm() {
      if (this.terms) {
        this.selectedTerm = this.terms.filter(
          (buyback_term) =>
            Number(buyback_term.terms) - parseInt(this.months) == 0
        )[0];
      }
    },
    btnBuyNow(){
      this.error = null
      this.order_id = null
      const payload = this.getData()
      if (!this.orderId){
          UserService.createOrders(this.leadId,
          payload
        )
        .then((response) => {
          if (!response.data.errors) {
            this.shall_proceed = true;
            this.order_id = response.data.id
            this.email = response.data.email
            this.mob_no = response.data.mob_no
          } else {
            this.error = response.data.errors;
          }
        })
        .catch((err)=>{
              this.error = err
          })
      }
      else{
        UserService.getOrderDetails(this.orderId)
        .then((response)=>{
          //console.log("response.data.", response.data)
          this.order_id = response.data.id
          this.email = response.data.email
          this.mob_no = response.data.mob_no
          this.shall_proceed = true;
        })
        .catch((err)=>{
              this.error = err
          })
        
      }
    },
    Proceed(){
      this.error = null
      if (this.mob_no && this.isValidMobile){
        let payload = this.getData()
        payload.mob_no = this.mob_no
        UserService.updateOrders(this.order_id, payload)
          .then((response)=>{
            if (!response.data.errors) {
              console.log("response.data.id", response.data.id)
              this.$router.push({ name: "Tnc",  params: {id: response.data.id} });
            }
            else {
              this.error = response.data.errors;
            }
          }).catch((err)=>{
              this.error = err
          })
      }
      
    },
    getData() {
      return {
        ex_showroom_price: this.ex_showroom_price,
        reg_no: this.lead.reg_no,
        on_road_price: this.on_road_price,
        coverage_price: Math.round(this.computedCoverCharge * 0.5),
        coverage_display_price: this.computedCoverCharge,
        validity_in_months: 60,
        city: this.lead.city,
        buyback_data: this.rate_card,
        asset_details: {
          reg_no: this.lead.reg_no,
          make_name: this.lead.variant.name,
          model_name: this.lead.variant.model.name,
          variant_name: this.lead.variant.name,
          fuel_type: this.lead.variant.fuel_type,
          transmission_type: this.lead.variant.transmission_type,
          rto_code: this.lead.reg_no.slice(0,4),
          reg_year: this.lead.reg_year,
          reg_month: this.lead.reg_month,
          variant: this.lead.variant.id,
          ex_showroom_price: this.ex_showroom_price,
          on_road_price: this.on_road_price,
          mob_no: this.mob_no,
        }
      }
    },
    goBack: function () {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
.handle {
  background-color: black !important;
}
.reg_no_bg {
  @media (max-width: 600px) {
    width: 100% !important;
  }
}
.reg_no_text {
  @media (max-width: 600) {
    left: 5em !important;
    font-size: x-large !important;
  }
}
</style>