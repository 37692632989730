import axios from "axios";


const APIUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:8000/'

const instance = axios.create({
  baseURL: APIUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;