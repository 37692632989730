<template>
  <div class="container-fluid min-vh-100">
    <div class="container-lg min-vh-100">
      <div class="row">
        <div class="col-md-12 mt-3" v-if="this.orderInfo?.status === 'PROCESSING' ">
            <div class="alert alert-success alert-dismissible fade show p-4">
              <span class="h6 text-center text-success">We will reach out on your phone / email for next steps around payment and detailed policy document with invoice</span>
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </div>
        <div class="col-md-12 mt-3">
          <div class="card card-watermark">
            <div class="card-watermark-behind">
              <h2 class="card-watermark-text card-watermark-success">
                {{ this.orderInfo?.status }}
              </h2>
            </div>
            <div class="card-header text-center">
              <button
                class="btn btn-warning float-md-start"
                @click="this.$router.push({ name: 'OrderList' })"
              >
                <i class="bi bi-arrow-left"></i> Back
              </button>
              <span
                class="reg_no_text text-center"
                style="
                  font-size: xx-large;
                  font-weight: 600;
                "
                >{{ this.orderInfo?.asset_details?.reg_no }}</span
              >
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12 col-sm-2 text-center">
                      <img class="text-center" height="80" :src="BrandLogo" />
                    </div>
                    <div class="col-md-12">
                      <div class="text-center">
                        <h5
                          class="text-center mt-4 text-uppercase"
                        >
                          {{ ModelName }}
                        </h5>
                        <h3>
                          <strong
                            class="badge bg-secondary-blue"
                            >{{ VariantName }}</strong
                          >
                        </h3>

                        <h6 class="m-0 p-0">
                          <strong class="text-center text-uppercase">
                            {{ Year }} | {{ FuelType }} | {{ TransmissionType }}  | {{ this.orderInfo?.city }}
                          </strong>
                        </h6>
                        <h4 class="pt-2 text-center">
                          <span class="badge bg-dark border border-bottom-1">
                            ₹
                            {{
                              Math.round(ExShowroomPrice)?.toLocaleString()
                            }} </span
                          ><br />
                          <strong class="p-0" style="font-size: small;">Ex-Showroom</strong>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12 h-100">
                      <div class="text-center border-start-md h-100 pt-4 pb-3">
                        <h5 class="text-danger text-center pt-4">
                          <span class="badge bg-dark">
                            Flat 50% Discount
                          </span>
                        </h5>
                        <h2 class="text-center pt-3">
                          <del
                            >Price: ₹
                            {{ Math.round(this.orderInfo?.coverage_display_price,0).toLocaleString() }}</del
                          ><br />
                          <span class="text-success"
                            ><strong
                              >₹
                              {{
                                Math.round(this.orderInfo?.coverage_price).toLocaleString()
                              }}</strong
                            ></span
                          >
                        </h2>
                        <br />
                        
                        <h4> <strong class="text-dark badge bg-secondary-blue text-white"><i class="bi bi-question-circle"></i> Need Help</strong></h4>
                          <!-- <a
                            href="tel:9873225791"
                            class="btn btn-sm btn-danger mt-1"
                            ><i class="bi bi-telephone-fill"></i> +91
                            9873225791</a
                          > -->
                          <p>
                          <a
                            href="mailto:buyback.newcars@cars24.com"
                            class="btn btn-sm mt-1" style="font-weight: 600;"
                            >
                            <i class="bi bi-envelope-fill"></i>
                            buyback.newcars@cars24.com</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="card-footer">
              <div class="text-center">
                <button class="btn btn-sm btn-primary">
                  Download Policy Doc</button
                >&nbsp;
                <button class="btn btn-sm btn-primary">cancel order</button
                >&nbsp;
                <a href="tel:9873225791" class="btn btn-sm btn-success"
                  ><i class="bi bi-telephone-fill"></i> Need Help</a
                >
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="row mb-3 mt-3">
        <div class="col-md-12">
          <div class="card">
            <!-- <div class="card-header">
              <p class="card-title text-center">Future Assured Buyback Grid</p>
            </div> -->
            <div class="card-body">
              <div class="table-responsive" v-if="this.rate_card">
                <table class="table">
                  <thead class="table-primary">
                    <tr class="table-group-divider">
                      <th></th>
                      <th
                        :colspan="this.rate_card[0].values.length"
                        class="text-center"
                      >
                        <strong>Rate Card</strong>[Assured Price (in lacs (incl.
                        of all taxes)]
                      </th>
                    </tr>
                    <tr>
                      <th rowspan="2" class="align-middle">
                        Mileage / Year (Avg)*
                      </th>
                      <th
                        :colspan="this.rate_card[0].values.length"
                        class="text-center"
                      >
                        Months from the date of purchase
                      </th>
                    </tr>
                    <tr>
                      <th
                        class="text-end"
                        v-for="fields in this.rate_card[0].values"
                        v-bind:key="fields.tenure_label"
                      >
                        {{ fields.tenure_label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    <tr v-for="item in this.rate_card" v-bind:key="item.label">
                      <th>{{ item.label }}</th>
                      <td
                        class="text-end"
                        v-for="rate in item.values"
                        v-bind:key="rate.tenure_label"
                      >
                        {{ (rate.dep_on_road_price / 100000).toFixed(2) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h6 class="card-title text-center pt-1">Application Status</h6>
          </div>
          <div class="card-body">
        <div class="col-md-6 offset-md-3">
          <div class="mt-4">
            <ul class="timeline">
              <li class="text-md-end">
                <div class="timeline-badge success">
                  <i class="bi bi-1-circle-fill"></i>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4 class="timeline-title">
                      Application Submitted
                    </h4>
                    <p>
                      <small class="text-muted"
                        ><i class="bi bi-stopwatch"></i>
                        {{ this.orderInfo?.submitted_at }}</small
                      >
                    </p>
                  </div>
                  <!-- <div class="timeline-body">
                            <p>you have completed your order successfully</p>
                          </div> -->
                </div>
              </li>
              <li class="timeline-inverted">
                <div :class="`timeline-badge ${!this.orderInfo?.processed_at ? 'muted': ''} `">
                  <i class="bi bi-2-circle-fill"></i>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4 :class="`timeline-title ${!this.orderInfo?.processed_at ? 'text-muted': ''} `">Policy Issued</h4>
                    <p>
                      <small :class="!this.orderInfo?.processed_at ? 'text-muted': '' ">
                        <i class="bi bi-stopwatch" v-if="this.orderInfo?.processed_at"></i>
                        {{ this.orderInfo?.processed_at }}
                        
                      </small>
                    </p>
                  </div>
                </div>
              </li>

              <!-- <li class="text-end" v-if="this.orderInfo?.claimed_at">
                <div class="timeline-badge mute">
                  <i class="bi bi-3-circle-fill"></i>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4 class="timeline-title text-muted">Buyback Claimed</h4>
                    <p>
                      <small class="text-muted">
                        <i class="bi bi-stopwatch"></i>
                        {{ this.orderInfo?.claimed_at }}
                        <span v-if="!this.orderInfo?.claimed_at"
                          >you application is still under process</span
                        >
                      </small>
                    </p>
                  </div>
                </div>
              </li> -->
              <li class="text-end" v-if="this.orderInfo?.canceled_at">
                <div class="timeline-badge mute">
                  <i class="bi bi-3-circle-fill"></i>
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4 class="timeline-title text-muted">Application Cancelled</h4>
                    <p>
                      <small class="text-muted">
                        <i class="bi bi-stopwatch" v-if="this.orderInfo?.canceled_at"></i>
                        {{ this.orderInfo?.canceled_at }}
                        
                      </small>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- <pre>{{ this.orderInfo }}</pre> -->
        </div>
      </div>
      </div>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/service/user.service";

export default {
  name: "OrderDetailsPage",
  watch: {
    $route: "fetchData",
  },
  data() {
    return {
      error: null,
      orderInfo: null,
      rate_card:null,
      showAlert: true
    };
  },
  created() {
    this.fetchData();
    //setTimeout(() => this.showAlert = false, 4000)
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
    ModelName() {
      return this.orderInfo?.asset_details.variant.model.display_name;
    },
    VariantName() {
      return this.orderInfo?.asset_details.variant.display_name;
    },
    Year() {
      return this.orderInfo?.asset_details.variant.year;
    },
    FuelType() {
      return this.orderInfo?.asset_details.variant.fuel_type;
    },
    TransmissionType() {
      return this.orderInfo?.asset_details?.variant?.transmission_type;
    },
    ExShowroomPrice() {
      return this.orderInfo?.asset_details?.variant?.ex_showroom_price;
    },
    GetMonthYear() {
      const date = new Date();
      return `${date.toLocaleString("default", {
        month: "long",
      })} ${date.getFullYear()}`;
    },
    BrandLogo() {
      return this.orderInfo?.asset_details?.variant?.model?.brand?.img_path;
    },
  },
  methods: {
    fetchData: function () {
      UserService.getOrderDetails(this.orderId)
        .then((response) => {
          this.orderInfo = response.data;
          this.rate_card = this.orderInfo?.buyback_data;
          // console.log("orderInfo", this.orderInfo);
        })
        .catch((err) => {
          this.error = err;
        });
    },
  },
};
</script>
<style scoped>
.card > .card-watermark {
  position: relative;
}
.card-watermark .card-watermark-behind {
  border: solid 1px lightgray;
  top: 40%;
  left: 40%;
  letter-spacing: 0.2em;
  text-align: center;
  position: absolute;
  /* color:lightgrey; */
  transform: rotate(340deg);
  -webkit-transform: rotate(340deg);
}
@media (max-width: 767px) {
  .card-watermark .card-watermark-behind {
    position: absolute;
    top: 52%;
    left: 18%;
  }
}

.card-watermark-text {
  padding: 10px;
  font-size: 30px;
}
.card-watermark-success {
  color: hwb(120 75% 11%) !important;
  border: solid 2px hwb(120 75% 11%);
}

.card-watermark-primary {
  color: lightskyblue;
  border: solid 2px lightskyblue;
}

.card-watermark-light {
  color: lightgray;
  border: solid 2px lightgray;
}

.card-watermark-danger {
  color: #f7bbbb;
  border: solid 2px #f7bbbb;
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eeeeee;
  left: 50%;
  margin-left: -1.5px;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -25px;
  background-color: #999999;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-badge.primary {
  background-color: #164065 !important;
}

.timeline-badge.success {
  background-color: #558455 !important;
}

.timeline-badge.warning {
  background-color: #f0ad4e !important;
}
.timeline-badge.danger {
  background-color: #f0ad4e !important;
}

.timeline-badge.danger {
  background-color: #d9534f !important;
}

.timeline-badge.info {
  background-color: #5bc0de !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

@media (max-width: 767px) {
  ul.timeline:before {
    left: 40px;
  }

  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }

  ul.timeline > li > .timeline-badge {
    left: 15px;
    margin-left: 0;
    top: 16px;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
</style>
