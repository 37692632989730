import axiosInstance from "./api";
//import TokenService from "./token.service";
//import store from "@/store";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = store.state.accessToken; //TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;  // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      console.log(" err.config",  err)
      const originalConfig = err.config;

      if (originalConfig.url !== "/login" && err.response) {
        // Access Token was expired
        if (err.config && err.response && err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          store.dispatch('refreshToken')
          .then(() => {
            return axiosInstance(originalConfig);
          })
          .catch( err => { 
            store.dispatch('logoutUser')
            .then(()=>{console.log("logout successfully")})
            .catch(()=>{console.log("logout failed")})
            Promise.reject(err);
          })    
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;