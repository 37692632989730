<template>
    <h3 class="pb-4">Select car model</h3>
    <!-- <input type="text" maxlength="30" autocomplete="off" 
            placeholder="select car model"
            class="form-control form-control-lg" 
            > -->
    
    <div class="mt-5 class=">
        <div class="row row-overflow-scroll">
            <h6>Popular Models</h6>
            <div class="col-6" v-for="model in this.models" :key="model.model_id">
                <div 
                    v-bind:class="model.model_id === this.selected_model?.model_id ?  'border p-2 mb-3 rounded text-center bg-warning': 'border p-2 mb-3 rounded text-center'"
                    >
                    <h5 @click="updateModel" v-bind:id="model.model_id">{{ model.model_display }} </h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// import HelloWorld from './components/HelloWorld.vue'
import {ref} from 'vue'
import { c24Service } from "@/service";

export default {
  name: 'ModelSelection',
  props: {
    //header: String,
    //title: String,
    disbaled: Boolean,
    //brand: String
  },
  emits:[
    "run-validation",
  ],
  setup() {
    let searchTerm = ref('')
    return {
      searchTerm
    }
  },
  data() {
    return {
        loading: false,
        is_valid:false,
        models: null,
        error: null,
        selected_model: null, //this.$store.state.model,
        brand: this.$store.state.make,
        year: this.$store.state.year,
    }
  },
  created() {
        this.fetchData()
  },
  methods: {    
    fetchData(){
        this.error = null
        c24Service.getModels(this.$store.state.make.make_id, this.$store.state.year).then(response => {
            console.log(response)
            this.models = []
            if (response.data.detail){
                this.models = response.data.detail.filter((model) => this.isModelEligible(model.model_id)) || []
            }
            console.log(this.models )
            this.brand = this.$store.state.make
            this.year = this.$store.state.year
            this.loading = false
        }).catch(e => {
            console.log(e)
            this.error = e
            this.loading = false
        })
        
    },
    getModel: function(model_id){
        return this.models?.filter((model)=> model.model_id.toString() === model_id)[0]
    },
    updateModel: function(event) {
        //console.log(event.target.id);
        this.selected_model = this.getModel(event.target.id)
        this.$store.commit('changeModel', {
            model: this.selected_model
        })
        this.$emit('run-validation', {
            data:this.get_data(),
            is_valid: this.isValid()
        })
    },
    isModelEligible(model_id){
        const m = this.$store.state.EligibleVariants?.filter((variant) => variant.model.id.toString() === model_id.toString()) || []
        if ( m.length ){
            return true
        }
        return false
    },
    get_data(){
        return {
            model: this.selected_model
        }
    },
    isValid(){
        if (this.selected_model && this.selected_model.model_id){
            return true
        }
        return false

    } 
  }
    
}
</script>