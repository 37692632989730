<template>
    <h3 class="pb-4">Select car manufacturing year</h3>
    <!-- <input type="text" maxlength="30" autocomplete="off" placeholder="select car manufacturing Year" class="form-control form-control-lg" change="filterModel" v-model="selected_year"> -->
    
    <div class="mt-5">
        <div class="row">
            <div class="col-12" v-for="(year) in years" :key="year">
                <div 
                    class="border mb-3 rounded text-center bg-light"
                    >
                    <button 
                            v-bind:class="year.toString() === this.selected_year ? 'btn btn-lg w-100 btn-warning' : 'btn btn-lg w-100 btn-light'" 
                            v-bind:id="year"
                            @click="updateYear"
                            >
                            {{ year }}</button>    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// import HelloWorld from './components/HelloWorld.vue'

import {  Utils } from "@/service";

export default {
  name: 'YearSelection',
  props: {
    //header: String,
    //title: String,
    disbaled: Boolean
  },
  emits:[
    "run-validation",
  ],
  data() {
    return {
        loading: false,
        is_valid:false,
        years: null,
        error: null,
        selected_year: null //this.$store.state.year,
    }
  },
  created() {
        this.fetchData()
  },
  methods: {
    fetchData(){
        this.error = null
        const year = Utils.getCurrentYear()
        this.years = [year, year-1]
    },
    updateYear: function(event) {    
        this.selected_year = event.target.id
        this.$store.commit('changeYear', {
            year: event.target.id
        })
        this.$emit('run-validation', {
            data:this.get_data(),
            is_valid: this.isValid()
        })
    },
    get_data(){
        return {
            year: this.selected_year
        }
    },
    isValid(){
        if (this.selected_year){
            return true
        }
        return false

    } 
  }
}
</script>