import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap"
//import { createAuth0 } from "@auth0/auth0-vue";

import { createApp } from 'vue';
import App from './App.vue';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret, faEnvelope, faLocationPin, faVideo, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faLink, faUser, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare, faTwitterSquare, faWhatsapp, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import router from "./router";
import store from "./store";
import setupInterceptors from '@/service/setupInterceptors';
//import authConfig from "../auth_config.json";
//import GAuth from 'vue-google-oauth2'


//import Vue from "vue";

library.add(faUserSecret,faFacebookSquare, faTwitterSquare, 
    faEnvelope, faWhatsapp, faInstagram, faYoutube, faLocationPin, faVideo,faGlobe,
    faLink, faUser, faPowerOff
)

setupInterceptors(store)

createApp(App)
.use(store)
.use(router)
// .use(GAuth, {
//     domain: authConfig.domain,
//     clientId: authConfig.clientId,
//     scope: 'profile email',
//     prompt: 'select_account'
    
//   })
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')