<template>
    <div class="min-vh-100" style="position: relative;">
        <picture>
            <source media="(min-width: 1440px" srcset="https://assets.cars24.com/production/c2b-website/230906154406/js/4989ce2bf9af2afac9087d7f3156e9f8.webp" type="image/webp">
            <source media="(min-width: 1440px)" srcset="https://assets.cars24.com/production/c2b-website/230906154406/js/63c80d59379bfa290442fa4a63d2f8ab.jpg" type="image/jpg">
            <img style="width:100%;" src="https://assets.cars24.com/production/c2b-website/230906154406/js/63c80d59379bfa290442fa4a63d2f8ab.jpg" alt="home banner" fetchpriority="high" />
        </picture>

        <div class="container-lg min-vh-100 py-3" style="position: absolute;top: 0px;right: 0px;left: 0px;">
            <div class="row ">
                <div class="col-md-6 d-table" style="background-color: transparent;">
                    <div class="middle text-white">
                        <h1 class="p-5 pt-0 pb-2 text-center"><strong>Assured Buyback Cover</strong></h1>
                        <div class="d-md-block d-none">
                            <ul class="list-group mt-2" style="background: transparent;">
                                <li class="list-group-item align-items-top d-flex" style="background: transparent;">
                                    <a class="text-white me-3"
                                     >
                                    <!-- <i class="bi bi-check-square-fill"></i> -->
                                    <img src="https://assets.cars24.com/production/c2b-website/231104162218/js/c2d8cd51e1fe8d59bbfdb7f2755d55fd.svg" />
                                    </a>
                                    <h4 class="text-white">Cars24 Guaranteed Minimum Future Value of Your Car</h4>
                                </li>
                                <li class="list-group-item align-items-top d-flex" style="background: transparent;">
                                    <a class="text-white me-3"
                                    role="button">
                                    <img src="https://assets.cars24.com/production/c2b-website/231104162218/js/c2d8cd51e1fe8d59bbfdb7f2755d55fd.svg" />
                                    </a>
                                    <h4 class="text-white">Fair Wear & Tear Policy</h4>
                                </li>
                                <li class="list-group-item align-items-top d-flex" style="background: transparent;">
                                    <a class="text-white me-3"
                                    >
                                    <img src="https://assets.cars24.com/production/c2b-website/231104162218/js/c2d8cd51e1fe8d59bbfdb7f2755d55fd.svg" />
                                    </a>
                                    <h4 class="text-white">Coverage From 1 to 6 Years</h4>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
                <div class="col-md-5 offset-md-1" >
                    <div class="rounded-container">
                        <div class="rounded-body">
                            <component :is="this.currentComponent.component" @run-validation="handleValidData"></component>
                        </div>
                        <div v-if="component_data">
                            <p>valid data from child:</p>
                            <pre>{{ component_data }}</pre>
                        </div>
                        <div class="rounded-footer">
                            <div class="row ">
                                <div class="col">
                                    <button type="button" class="btn btn-dark w-100" style="align-items: center;" @click=this.prev>Prev</button>
                                </div>
                                <div class="col">
                                    <button type="button" class="btn btn-primary w-100" :disabled="this.is_valid_data === false" style="align-items: center;" @click=this.next>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <!-- <div class="text-center">
                <div v-if="valid_data">
                    <p>valid data from child:</p>
                    <pre>{{ valid_data }}</pre>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
// import NavHeader from "@/components/NavHeader.vue";
import { ref, shallowRef } from 'vue';
import  RegNoSelection  from "@/components/home/RegNoSelection.vue";
import  YearSelection  from "@/components/home/YearSelection.vue";
import  ModelSelection  from "@/components/home/ModelSelection.vue";
import  FuelnTransmissionSelection  from "@/components/home/FuelnTransmissionSelection.vue";
import  VariantSelection  from "@/components/home/VariantSelection.vue";
//import  OdoSelection  from "@/components/home/OdoSelection.vue";
import  citySelection from "@/components/home/citySelection.vue";
import userService from '@/service/user.service';

    export default {
        name: "HomePage",
        components:{
            //NavHeader
            RegNoSelection,
            YearSelection,
        },
        data () {
            return {
                loading: false,
                channels: null,
                error: null,
                defaultComponent: shallowRef({"component": RegNoSelection, "next": YearSelection, "prev": RegNoSelection}), 
                //defaultComponent: {"component": YearSelection, "next": ModelSelection, "prev": RegNoSelection} , 
                componentList: [
                    {"component": shallowRef(RegNoSelection), "next": shallowRef(YearSelection), "prev": shallowRef(RegNoSelection)} , 
                    {"component": shallowRef(YearSelection), "next": shallowRef(ModelSelection), "prev": shallowRef(RegNoSelection)} , 
                    {"component": shallowRef(ModelSelection), "next": shallowRef(FuelnTransmissionSelection), "prev": shallowRef(YearSelection)} , 
                    {"component": shallowRef(FuelnTransmissionSelection), "next": shallowRef(VariantSelection), "prev": shallowRef(ModelSelection)} , 
                    {"component": shallowRef(VariantSelection), "next": shallowRef(citySelection), "prev": shallowRef(FuelnTransmissionSelection)} , 
                    {"component": shallowRef(citySelection), "next": shallowRef(citySelection), "prev": shallowRef(VariantSelection)} , 
                    
                ],
                currentComponent: null,
                component_data: ref(''),
                is_valid_data:  false,
                valid_data: {

                }
            }
        },
        computed() {
            return {
                isValid(){
                    console.log("validation", this.currentComponent.component.isValid())
                    return this.currentComponent.component.methods.isValid()
                }
            }
        },
        created(){
            //this.fetchData()
            this.currentComponent =  shallowRef(this.defaultComponent)
            this.loadEligibleVariants()
        },
        methods: {
            next: function() {
                let last_component = this.componentList[this.componentList.length - 1].component;
                if (last_component.name == this.currentComponent.component.name) {
                    //create lead and redirect to buyback
                    let reg_no  = this.$store.state.reg_no;
                    let reg_date = this.$store.state.reg_date
                    if (reg_no){
                        reg_no = reg_no.toString().toUpperCase()
                        userService.createLead({    
                            reg_no: reg_no,
                            variant: this.$store.state.variant.variant_id,
                            city: this.$store.state.city,
                            //mob_no: this.$store.state.user.mob_no,
                            reg_month: reg_date.getMonth() + 1,
                            reg_year: reg_date.getFullYear()
                        
                        }).then(response =>{
                            let lead  = response.data
                            if (lead && !lead.error){
                                this.$router.push({name: 'Buyback', params: {'id': lead.id}})
                            }
                            else{
                                alert("something goes wrong1")
                            }
                        }).catch(err =>{
                            console.log("sdds err", err);
                        });
                        //console.log("lead", this.lead)
                    }
                    else{
                        alert("please enter select reg no")
                    }
                    
                }
                if (!this.is_valid_data){
                    alert("invalid data")
                    return 
                }
                else{
                    
                    let current_c = this.componentList.filter((component) => {
                    if (this.currentComponent.next.name == component.component.name){
                        return  component
                    }
                    })
                    this.currentComponent = shallowRef(current_c[0]);
                    this.is_valid_data = false
                }
                
            },
            prev: function() {
                let current_c = this.componentList.filter((component) => {
                    if (this.currentComponent.prev.name == component.component.name){
                        //console.log("component",this.currentComponent.next.name )
                        return  component
                    }
                })
                this.currentComponent = shallowRef(current_c[0]);
            },
            handleValidData(payload) {
                
                this.is_valid_data = payload.is_valid
                if (this.is_valid_data){
                    this.valid_data = {...this.valid_data, ...payload}
                }
            },
            loadEligibleVariants(){
                this.$store.dispatch('getBuybackVariants')
                .then(()=>{
                    console.log("elibigle variants loaded")
                })
                .catch((err)=>{
                    console.log("error in loading eligible variants", err);
                    //alert("somthing goes wrong!")
                })
            }
        },
        
    }
</script>

<style>
.table-easy > tbody > tr > td, 
.table-easy > tbody > tr > th,
.table-easy > tr > td
{
  padding-top: 1em;
  padding-bottom: 1em;
}
.router-link:link, .router-link:hover, .router-link  {
    color: black;
    text-decoration: none;
}
.router-link:hover {
    font-weight: bold;
}

@media(max-width:600px) {
    .m-hide {
        display: none;
    }
}

.overlap-container {
    /* justify-content: space-between !important;  */
    position: absolute;
    /* min-height: 768px; */
    top: 80px;
}

.d-table {
    display: table;
}

.top {
    display: table-cell;
    width:50%;
    height: 100%;
}

.bottom {
    display: table-cell;
    width:50%;
    vertical-align: bottom;
    
}
.middle {
    display: table-cell;
    width:50%;
    vertical-align: middle;
    
}

.rounded-container {
    border-radius: 32px;
    padding: 60px 24px 12px 24px;
    /* width: 514px; */
    height: 660px;
    background-color: #fff;
}
.rounded-body {
    height: 90%;
    background-color: #fff;
}

.rounded-footer {
    height: 10%;
    background-color: #fff;
}
ul.row > li {
    padding: 5px;   
}
ul.row > li:hover {
    cursor: pointer;
}
.brand-img  {
    border-radius: 8px;
    
}
.brand-img > img {
    padding: 20px; 
    width: 100px;
    
}
.row-overflow-scroll {
    overflow-y: scroll;
    max-height:350px;
}

ul.back-cover {
    list-style: none;
    font-size: x-large;
}
    ul.back-cover > li {
        padding-left: var(--icon-space);
    }
    ul.back-cover > li::before {
        content: "\F3DA"; /* FontAwesome Unicode */
        font-family: webfont;
        display: inline-block;
        margin-left: calc( var(--icon-space) * -1 );
        width: var(--icon-space);
    }

</style>