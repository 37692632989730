<template>
    <div class="mt-2">
        <h3 class="pb-4">Select registration month year</h3>
        <div class="row">
            <div class="col-12">
                <div class="form-control-group text-center">
                    <datepicker 
                        placeholder="Select Reg Month/YYYY"
                        v-model="reg_date"
                        minimum-view="month" 
                        name="reg_date"
                        :disabled-dates="this.disabledDates"
                        @update:modelValue="this.selectRegDate"
                        >
                    </datepicker>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-1">
        <h3 class="pb-4 pt-2">Select fuel type</h3>
        <div class="row">
            <div class="col-6" v-for="(fuel_type) in fuleTypes" :key="fuel_type">
                <div 
                    v-bind:class="fuel_type.title === selected_fuel_type ? 'border p-1 mb-2 rounded text-center bg-warning' : 'border p-1 mb-2 rounded text-center'"
                    >
                    <h5  @click="selectFuel" v-bind:title="fuel_type.title">
                        <img v-bind:src="fuel_type.img_path" v-bind:title="fuel_type.title" @click="selectFuel" /> {{ fuel_type.title }}
                    </h5>    
                </div>
            </div>
        </div>
    </div>

    <h3 class="pb-1 pt-1">Select transmission type</h3>
    <div class="mt-2">
        <div class="row">
            <div class="col-6" v-for="(trans) in transmissions" :key="trans">
                <div v-bind:class="trans === selected_transmission_type ? 'border p-2 mb-1 rounded text-center bg-warning': 'border p-2 mb-1 rounded text-center' ">
                    <h5  @click="updateTransType" v-bind:title="trans">{{ trans }}</h5>    
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { c24Service } from "@/service";
import Datepicker from 'vuejs3-datepicker';
import { ref } from 'vue';

const mydate = new Date();
mydate.setDate(mydate.getDate() - 180)

export default {
  name: 'FuelnTransmissionSelection',
  components: {
    Datepicker
  },
  props: {
    //header: String,
    //title: String,
    disbaled: Boolean,
    brand: String
  },
  emits:[
    "run-validation",
  ],
  data() {
    return {
        loading: false,
        is_valid:false,
        reg_date: ref(''),
        valid_data: {
            fuel_type: null,
            trans_type: null,
        },
        fuleTypes: null,
        transmissions: null,
        selected_transmission_type: null, // this.$store.state.transmission_type,
        selected_fuel_type: null, // this.$store.state.fuel_type,
        variants:null,
        disabledDates: {
            to: mydate,
            from: new Date(),
        }
    }
  },
  created() {
        this.fetchData()
  },
  methods: {
    fetchData(){
        this.error = null
        this.fuleTypes = [
            {'title': "Petrol", img_path: "https://assets.cars24.com/production/c2b-website/230912153210/js/0c4e4e082840ea1e9436d6368254a544.svg"},
            {'title': "Diesel", img_path: "https://assets.cars24.com/production/c2b-website/230912153210/js/6836efc08bc36d64a7706d2d792411c2.svg"},
            {'title': "CNG", img_path: "https://assets.cars24.com/production/c2b-website/230912153210/js/c7f35e8d8fdd7a3f55282088db283dc4.svg"},
            {'title': "Hybrid", img_path: "https://assets.cars24.com/production/c2b-website/231109160912/js/ffdd44df5aa57fd63c91b8336bdf97a2.svg"},
        ]

        c24Service.getVariants( this.$store.state.model.model_id, this.$store.state.year).then(response => {
            //console.log("response.data.detail[this.fuel_type]", response.data.detail[this.fuel_type])
            this.$store.commit('updateApiVariants', {
                data: response.data.detail
            })
            
            let fuels =Object.keys(response.data.detail)
            
            this.fuleTypes = this.fuleTypes.filter((fuel)=> fuels.indexOf(fuel.title) >= 0)

            //this.variants = response.data.detail
            this.loading = false
            //console.log(this.variants)
        }).catch(e => {
            console.log(e)
            this.error = e
            this.loading = false
        })

        this.inital_transmissions = [
            "Automatic",
            "Manual"
        ]
        },
        selectRegDate(){
            this.$store.commit('changeRegDate', {
                date: this.reg_date
            })
            this.$emit('run-validation', {
                data:this.get_data(),
                is_valid: this.isValid()
            })
        },
        selectFuel(event){
            // console.log(event.target.title);
            this.selected_fuel_type = event.target.title
            this.$store.commit('changeFuel', {
                fuel_type: event.target.title
            })
            //let transmissions = this.$store.state.api_variants[this.selected_fuel_type].map(v => v.title)
            const ts_types = [...new Set(this.$store.state.api_variants[this.selected_fuel_type].map(item => item.title))];
            // console.log("ts_types", ts_types)
            this.transmissions = this.inital_transmissions.filter((ts)=> ts_types.indexOf(ts) >= 0)
            
            this.$emit('run-validation', {
                data:this.get_data(),
                is_valid: this.isValid()
            })

        },
        updateTransType(event){
            // console.log(event.target.title);
            this.selected_transmission_type = event.target.title
            this.$store.commit('changeTransmission', {
                transmission_type: event.target.title
            })
            this.$emit('run-validation', {
                data:this.get_data(),
                is_valid: this.isValid()
            })
        },
        get_data(){
            return {
                date:  this.reg_date,
                fuel_type: this.selected_fuel_type,
                transmission_type: this.selected_transmission_type
            }
        },
        isValid(){
            if (this.reg_date 
                && this.selected_fuel_type
                && this.selected_transmission_type
                ){
                return true
            }
            return false

        } 
    }
}
</script>
<style>
.vuejs3-datepicker {
    border: solid 1px var(--bs-border-color) !important;
    width: 100%;
    border-radius: 5px
}
#calendar-div .vuejs3-datepicker__value {
    border: none !important;
}
.vuejs3-datepicker__calendar-topbar {
    background-color: #ef6e0b !important;
}
.vuejs3-datepicker__content {
    font-size: large;
}
</style>