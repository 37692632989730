<template>
    <h3 class="pb-4">select car city</h3>
    <!-- <input type="text" maxlength="30" autocomplete="off" 
            placeholder="select car city"
            class="form-control form-control-lg" 
            v-model="q"
            > -->
    

    <div class="mt-5">
        <div class="row row-overflow-scroll">
            <div class="col-12" v-for="city in this.cities" :key="city">
                <div 
                   v-bind:class="city === selected_city ? 'border p-2 mb-3 rounded text-center bg-warning': 'border p-2 mb-3 rounded text-center' ">
                    <h5 v-bind:title="city"  @click="selectCity">{{ city }}</h5>    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import api from "@/service/api";

export default {
  name: 'citySelection',
  props: {
    //header: String,
    //title: String,
    disbaled: Boolean,
  },
  emits:[
    "run-validation",
  ],
  data() {
    return {
        loading: false,
        q: ref(''),
        is_valid:false,
        error: null,
        cities: ref(''),
        selected_city: null, // this.$store.state.city ? this.$store.state.city.city : ''
    }
  },
  created() {
        this.fetchData()
  },
  methods: {
    fetchData(){
        api.get(`/api/buyback/cities/${this.$store.state.variant.variant_id}/`)
        .then(response => {
            this.cities = response.data
        }).catch(err =>{
            console.log(err)
            alert('something goes wrong')
        })
    },
    getCity: function(title){
        return this.cities.filter((city)=> city.toString() === title)[0]
    },
    selectCity(event){
        this.selected_city = this.getCity(event.target.title);
        this.$store.commit('changeCity',{
            city: this.selected_city
        })
        this.$emit('run-validation', {
            data:this.get_data(),
            is_valid: this.isValid()
        })
    },

    get_data(){
        return {
            city: this.selected_city
        }
    },
    isValid(){
        if (this.selected_city){
            return true
        }
        return false

    } 
  }
    
}
</script>