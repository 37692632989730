import axios from "axios";  
//import store from "@/store";
import { Buffer } from 'buffer';

const APIUrl = process.env.VUE_APP_BASE_URL || 'http://127.0.0.1:8000/'
const C24APIUrl = "https://vehicle.cars24.team"

const c24api = axios.create({  
  //baseURL: "http://35.207.211.245:445/api/",  
  baseURL: C24APIUrl,  
  headers: {  
    "Content-Type": "application/json",
    //"Access-Control-Allow-Origin": "*"
    "Authorization": "Basic " + Buffer.from("c2b_frontend:J5ItfA96m2_cyQVM4tKNJpXhRtsCmcXu").toString('base64'),
    "Accept": "*/*"
  }
})

// const axiosBase = axios.create({
//   baseURL: APIUrl,
//   headers: { 'Content-Type': 'application/json' }
// })

const getAPI = axios.create({
  baseURL: APIUrl,
  headers: { 'Content-Type': 'application/json' }
})


// axiosBase.interceptors.response.use(undefined, function (err) {
//   // if error response status is 401, it means the request was invalid due to expired access token
//   if (err.config && err.response && err.response.status === 401) {
//     store.dispatch('refreshToken') // attempt to obtain new access token by running 'refreshToken' action
//       .then(access => {
//         // if successful re-send the request to get the data from server
//         axios.request({
//           baseURL: APIUrl,
//           method: 'get',
//           headers: { Authorization: `Bearer ${access}` }, // the new access token is attached to the authorization header
//           url: '/api/auth/health'
//         }).then(response => {
//           // if successfully received the data store it in store.state.APIData so that 'Downloads' component can grab the
//           // data from it and display to the client.
//           console.log('Success getting the Mods')
//           store.state.APIData = response.data
//         }).catch(err => {
//           console.log('Got the new access token but error while trying to fetch data from the API using it')
//           return Promise.reject(err)
//         })
//       })
//       .catch(err => {
//         return Promise.reject(err)
//       })
//   }
// })

class cars24Service {
  
  getMakes(){
    return c24api.get("/make");
  }
  getModels(brandId,year){
    return c24api.get("/make/"+ brandId +"/model/" + year);
  }
  getVariants(modelId,year){
    return c24api.get(`variant-fuel-list?modelId=${modelId}&year=${year}` );
  }
}

class UtilsClass {

  shortNumber(num, digits=2){
    var units = ['K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'],
        decimal;
      for(var i=units.length-1; i>=0; i--) {
          decimal = Math.pow(1000, i+1);
          if(num <= -decimal || num >= decimal) {
              return +(num / decimal).toFixed(digits) + units[i];
          }
      }
  }
  getCurrentYear(){
    return new Date().getFullYear()
  }
}

const c24Service = new cars24Service();
const Utils = new UtilsClass();

export { c24Service, Utils,  getAPI };
