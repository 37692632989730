<template>
    <div class="row bg-secondary-blue">
        <div class="col-md-12 text-center">
        <!-- <ul class="list-group list-group-horizontal m-2 w-100"> -->
            <div class="btn-group" role="group" aria-label="Basic outlined example p-1">
            <a href="#" role="button" class="btn btn-outline-light rounded-0">ALL Orders</a>
            <a href="#" role="button" class="btn btn-outline-light">Pending Orders</a>
            <a href="#" role="button" class="btn btn-outline-light rounded-0">Completed Orders</a>
            </div>
        <!-- </ul> -->
        </div>
    </div>
    <div class="container-fluid min-vh-100">
        <div class="container-lg min-vh-100">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <div class="row mt-4">
                        <div class="col-md-12">
                            <h2><span class="text-capitalize text-bold">Pending Buyback orders</span></h2>
                            <hr/>
                            <h6><small class="text-small">Continue orders where you left off</small></h6>
                            
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="bg-secondary card-img-top">
                                    <moving-car></moving-car>
                                    <hr class="m-0" />
                                </div>
                                
                                <div class="card-body">
                                    <h4 class="card-title text-center pt-3"><span class="badge bg-secondary-blue">MARUTI SUZUKI</span> </h4>
                                    <hr/>
                                    <h4 class="card-title text-center"></h4>
                                    
                                    <p class="card-text text-center"> 2021 | Petrol | MT</p>
                                    <p class="card-text text-end"><a href="" class="btn btn-primary text-end">Continue</a></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                
                                
                                <div class="card-body">
                                    <h5 class="card-title text-center pt-3">Why Choose Our Buy Assured Buyback Car ?</h5>
                                    <hr/>
                                    <ol class="list-item-big">
                                        <li>
                                            <strong class="h4">Predictable Future Value: </strong><br/>
                                            We understand that your car is an investment. With our Buy Assured Buyback Car, you can confidently predict its future value, giving you financial security when it's time to upgrade or change your vehicle.
                                        </li>
                                    </ol>
                                    
                                    <p class="card-text text-end"><a href="" class="btn btn-primary text-end">Know More</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import MovingCar from '@/components/MovingCar.vue';
import  UserService  from "@/service/user.service";

export default {
    name: "UserBuybackPage",
    components: { MovingCar },
    data(){
        return {

        }
    },
    created(){
        this.fetchData()
    },
    methods: {
        fetchData: function() {
            UserService.getLeads((response)=>{
                console.log(response)
            }).catch((err)=>{
                console.log(err)
            })   
        }
    }
    
}

</script>
<style scoped>

</style>